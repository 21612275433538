<template>
<div class="view-box">
    <div>
        <left-nav class="let-nav"></left-nav>
    </div>
    <div class="content-box">
        <top-nav class="top-nav"></top-nav>
        <tags-view></tags-view>
        <div class="main-box">
            <router-view class="main-content" />
        </div>
    </div>
</div>
</template>

<script lang="ts">
import topNav from './components/topNav.vue';
import leftNav from './components/leftNav.vue';
import tagsView from "./components/tagsView/tagsView.vue";

export default {
    components: {
        leftNav,
        topNav,
        tagsView
    },
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
.view-box {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;

    .main-box {
        position: relative;
        height: calc(100vh - 100px);
        background-color: #f2f2f2;
        padding: 20px;
        overflow-y: auto;
        // overflow-x: hidden;

        .main-content {
            // background-color: #fff;
            width: 100%;
            min-height: calc(100vh - 136px);
        }

        .company-name {
            padding-top: 20px;
            color: #333;
            font-size: 12px;
            text-align: center;

        }
    }

    .content-box {
        width: 100%;
        position: relative;
    }
}
</style>
