import store from '../store'
import Enums from './enums'
//   广告商
const advertRoutes = [
    {
        path: "/article", name: "文章管理",
        icon: require('../assets/menu/article.png'),
        children: [{ path: "/article/articleCategory", name: "文章分类" }, { path: "/article/articleList", name: "文章列表" }]
    },
    {
        path: "/album", name: "相册管理",
        icon: require('../assets/menu/album.png'),
        children: [{ path: "/album/albumList", name: "相册列表" }]
    },
    {
        path: "/goods", name: "商品管理",
        icon: require('../assets/menu/goods.png'),
        children: [{ path: "/goods/goodsCategory", name: "商品分类" }, { path: "/goods/goodsList", name: "商品列表" }]
    },
    {
        path: "/questionnaire", name: "问卷管理",
        icon: require('../assets/menu/questionnaire.png'),
        children: [{ path: "/questionnaire/questionnaireList", name: "问卷列表" }]
    },
    {
        path: "/advertContent", name: "广告内容管理",
        icon: require('../assets/menu/advert.png'),
        children: [{ path: "/advertContent/advertList", name: "广告列表" }]
    },
    {
        path: "/advertDeliver", name: "广告投放管理",
        icon: require('../assets/menu/advert.png'),
        children: [{ path: "/advertDeliver/deliverList", name: "投放列表" }]
    },
    {
        path: "/store", name: "基础信息",
        icon: require('../assets/menu/basic.png'),
        children: [{ path: "/store/basicInformation", name: "基础信息" }]
    },
    {
        path: "/advertAccount", name: "账户管理",
        icon: require('../assets/menu/account.png'),
        children: [{ path: "/advert/accountAdmin", name: "投放账户管理" }]
    },
    {
        path: "/user", name: "用户管理",
        icon: require('../assets/menu/user.png'),
        children: [{ path: "/user/userList", name: "用户列表" }]
    },
];
// 餐饮
const restaurantRoutes = [
    {
        path: "/space", name: "广告位管理",
        icon: require('../assets/menu/advert.png'),
        children: [{ path: "/space/spaceList", name: "广告位" }]
    },
    {
        path: "/store", name: "基础信息",
        icon: require('../assets/menu/basic.png'),
        children: [{ path: "/store/basicInformation", name: "基础信息" }]
    },
    {
        path: "/restaurantAccount", name: "账户管理",
        icon: require('../assets/menu/account.png'),
        children: [{ path: "/restaurant/accountAdmin", name: "账户管理" }]
    }, 
    {
        path: "/user", name: "用户管理",
        icon: require('../assets/menu/user.png'),
        children: [{ path: "/user/userList", name: "用户列表" }]
    },
    {
        path: "/record", name: "广告记录",
        icon: require('../assets/menu/user.png'),
        children: [{ path: "/record/recordList", name: "广告记录管理" }]
    },
]
function addRoutesByRole(role) {
    if (role === Enums.companyTypes.RESTAURANT.value) {
        store.dispatch("permissionRepast/getMenuList", restaurantRoutes)
    } else if (role === Enums.companyTypes.ADVERT.value) {
        store.dispatch("permissionRepast/getMenuList", advertRoutes)
    }
}

export { addRoutesByRole }