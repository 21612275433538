const toArray = function () {
    return Object.keys(this).filter(key => key !== 'toArray').map((key) => this[key])
}
const fileTypes = {
    UNKNOWN: { value: 0, name: "未知" },
    IMAGE: { value: 1, name: "图片" },
    VIDEO: { value: 2, name: "视频" },
    toArray
}
const elementType = {
    IMAGE: { value: 'image', name: "图片" },
    TEXT: { value: 'text', name: "文本" },
    TITLE: { value: 'title', name: "标题" },
    VIDEO: { value: 'video', name: "视频" },
    BUTTON: { value: 'button', name: "按钮" },
    DIVIDER: { value: 'divider', name: "分割线" },
    MAP: { value: 'map', name: "地图" },
    TIMER: { value: 'timer', name: "倒计时" },
    CAROUSEL_IMAGE: { value: 'carouselImage', name: "轮播图片" },
    ATLAS: { value: 'atlas', name: "图集" },
    LIST: { value: 'list', name: "列表" },
    ARTICLE: { value: 'article', name: "图文" },
    toArray
}
// 问卷题目类型
const questionType = {
    RADIO: { value: 1, name: "单选题" },
    CHECKBOX: { value: 2, name: "多选题" },
    INPUT: { value: 3, name: "简答题" },
    IMAGE: { value: 4, name: "图片" },
    VIDEO: { value: 5, name: "视频" },
    toArray
}
// 账户资金变动类型
const accountChangeType = {
    INVESTMENT: { value: 1, name: "投放收入" },
    WATCH: { value: 2, name: "观看奖励" },
    JUMP: { value: 3, name: "跳转奖励" },
    IN_GROUP: { value: 4, name: "进群奖励" },
    QUESTIONNAIRE: { value: 5, name: "问卷奖励" },
    SHARE: { value: 6, name: "分享奖励" },
    toArray
}
//账户类型
const companyTypes = {
    ADVERT: { value: 4, name: "广告商" },
    RESTAURANT:{ value: 5, name: "餐饮商家" },
    toArray
}
const questOption = {
    SIMPLE: { value: 'quest-simple', name: "简约" },
    toArray
}
export default {
    fileTypes,
    elementType,
    questionType,
    accountChangeType,
    companyTypes,
    questOption
}