import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'
import Cache from '../utils/cache'
import Layout from '@/layout/index.vue'
import routerView from '@/components/common/routerView.vue';
import { addRoutesByRole } from '@/utils/dynamicRoutes';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/login.html',
		name: 'login',
		component: () => import('../views/passport/login.vue'),
		meta: {
			title: '登录',
		}
	},
	{
		path: '/join.html',
		name: 'join',
		component: () => import('../views/passport/join.vue'),
		meta: {
			title: '注册账号',
			keepAlive: true
		},
	},
	{
		path: '/',
		name: 'index',
		component: Layout,
		redirect: '/',
		children: [{
			path: '/home',
			name: 'home',
			children: [
				{
					path: '/',
					name: 'home',
					component: () => import('../views/home/dashboard.vue'),
				}
			]
		},],
	},
	{
		path: '/article',
		component: Layout,
		children: [
            {
                path: '/article/articleCategory',
                name: 'articleCategory',
                component: () => import('@/views/advertAdmin/articleAdmin/articleCategory.vue'),
                meta: {
                    title: '文章分类',
                }
            },
			{
                path: '/article/articleList',
                name: 'articleList',
                component: () => import('@/views/advertAdmin/articleAdmin/articleList.vue'),
                meta: {
                    title: '文章列表',
                }
            },
        ]
	},
	{
		path: '/album',
		component: Layout,
		children: [
			{
                path: '/album/albumList',
                name: 'albumList',
                component: () => import('@/views/advertAdmin/albumAdmin/albumList.vue'),
                meta: {
                    title: '相册列表',
                }
            },
        ]
	},
	{
		path: '/goods',
		component: Layout,
		children: [
            {
                path: '/goods/goodsCategory',
                name: 'goodsCategory',
                component: () => import('@/views/advertAdmin/goodsAdmin/goodsCategory.vue'),
                meta: {
                    title: '商品分类',
                }
            },
			{
                path: '/goods/goodsList',
                name: 'goodsList',
                component: () => import('@/views/advertAdmin/goodsAdmin/goodsList.vue'),
                meta: {
                    title: '商品列表',
                }
            },
        ]
	},
	{
		path: '/questionnaire',
		component: Layout,
		children: [
			{
                path: '/questionnaire/questionnaireList',
                name: 'questionnaireList',
                component: () => import('@/views/advertAdmin/questionnaireAdmin/questionnaireList.vue'),
                meta: {
                    title: '问卷列表',
                }
            },
			{
                path: '/questionnaire/questionnaireTemplate',
                name: 'questionnaireTemplate',
                component: () => import('@/views/advertAdmin/questionnaireAdmin/questionnaireTemplate.vue'),
                meta: {
                    title: '问卷编辑',
                }
            }
        ]
	},
	{
		path: '/store',
		component: Layout,
		children: [
			{
                path: '/store/basicInformation',
                name: 'basicInformation',
                component: () => import('@/views/advertAdmin/storeAdmin/basicInformation.vue'),
                meta: {
                    title: '基础信息',
                }
            },
        ]
	},
	{
		path: '/user',
		component: Layout,
		children: [
			{
                path: '/user/userList',
                name: 'userList',
                component: () => import('@/views/advertAdmin/userAdmin/userList.vue'),
                meta: {
                    title: '用户列表',
                }
            },
        ]
	},
	{
		path: '/user',
		component: Layout,
		children: [
			{
                path: '/user/userList',
                name: 'userList',
                component: () => import('@/views/advertAdmin/userAdmin/userList.vue'),
                meta: {
                    title: '用户列表',
                }
            },
        ]
	},
	{
		path: '/advertContent',
		component: Layout,
		children: [
			{
                path: '/advertContent/advertList',
                name: 'advertList',
                component: () => import('@/views/advertAdmin/advertContent/advertList.vue'),
                meta: {
                    title: '广告列表',
                }
            }
        ]
	},
    {
		path: '/advertDeliver',
		component: Layout,
		children: [
			{
                path: '/advertDeliver/deliverList',
                name: 'delivertList',
                component: () => import('@/views/advertAdmin/advertDeliver/deliverList.vue'),
                meta: {
                    title: '投放列表',
                }
            }
        ]
	},
	{
		path: '/advertAdd',
		component: routerView,
		redirect: '/advertAdd',
		children: [
			{
                path: '/advertAdd',
                name: 'advertAdd',
                component: () => import('@/views/advertAdd/advertAdd.vue'),
                meta: {
                    title: '广告列表',
                }
            }
        ]
	},
	{
		path: '/advertAccount',
		component: Layout,
		children: [
            {
                path: '/advert/accountAdmin',
                name: 'advertAccount',
                component: () => import('@/views/advertAdmin/accountAdmin/accountAdmin.vue'),
                meta: {
                    title: '投放账户管理',
                }
            },
			{
                path: '/accountAdmin/accountDialog',
                name: 'accountDialog',
                component: () => import('@/views/advertAdmin/accountAdmin/accountDialog.vue'),
                meta: {
                    title: '投放账户详情',
                }
            },
        ]
	},
	{
		path: '/space',
		component: Layout,
		children: [
            {
                path: '/space/spaceList',
                name: 'spaceList',
                component: () => import('@/views/restaurantAdmin/advertisingSpace/advertisingSpace.vue'),
                meta: {
                    title: '广告位',
                }
            },
        ]
	},
	{
		path: '/store',
		component: Layout,
		children: [
			{
                path: '/store/basicInformation',
                name: 'basicInformation',
                component: () => import('@/views/restaurantAdmin/storeAdmin/basicInformation.vue'),
                meta: {
                    title: '基础信息',
                }
            },
        ]
	},
    {
		path: '/user',
		component: Layout,
		children: [
			{
                path: '/user/userList',
                name: 'userList',
                component: () => import('@/views/restaurantAdmin/userAdmin/userList.vue'),
                meta: {
                    title: '用户列表',
                }
            },
        ]
	},
    {
		path: '/restaurantAccount',
		component: Layout,
		children: [
            {
                path: '/restaurant/accountAdmin',
                name: 'restaurantAccount',
                component: () => import('@/views/restaurantAdmin/accountAdmin/accountAdmin.vue'),
                meta: {
                    title: '账户管理',
                }
            },
        ]
	},
    {
		path: '/record',
		component: Layout,
		children: [
            {
                path: '/record/recordList',
                name: 'record',
                component: () => import('@/views/restaurantAdmin/advertRecord/advertRecord.vue'),
                meta: {
                    title: '广告记录',
                }
            },
        ]
	},
]

const router = createRouter({
	// process.env.BASE_UR
	history: createWebHistory("/"),
	routes
})
const whiteList = ['/login.html', '/join.html']; //白名单

router.beforeEach(async (to, from, next) => {
	let accessToken = store.getters.token || Cache.getToken(Cache.advertToken)
	const toPath: string = to.path;
    document.title = to.meta.title ? String(to.meta.title) : '本家广告管理后台';
	if (accessToken) {
		let user = store.getters.advertInfo || Cache.getStorage(Cache.advertUser);
		if (toPath === '/login.html') {
            return next({ path: '/' });
        }
		if (!user || !user.id) {
			try {
                const res = await store.dispatch("userAdvert/getInfo");
                if (!res.role || res.role === 1 || res.role === 3) {
                    if (toPath !== '/join.html') {
                        return next(`/join.html`);
                    }
                } else {
                    return next('/');
                }
            } catch (error) {
                // 处理获取用户信息失败的情况
                console.error("获取用户信息失败:", error);
                return next('/');
            }
		}else{
            addRoutesByRole(user.companyType);
        }
	    // 如果用户没有菜单列表或者路由不存在，添加路由并重定向
		if (toPath !== '/join.html' && (store.getters.menuList.length <= 0 || !router.hasRoute(to.name))) {

			return next({ path: '/' });
		}
		return next();
	}else{
		// 没有token
		if (whiteList.indexOf(to.path) !== -1) {
			// 在免登录白名单，直接进入
			next()
		} else {
			next(`/login.html`)
		}
	}
})
export default router
